@import url('https://fonts.cdnfonts.com/css/cabinet-grotesk');
@import url('https://fonts.cdnfonts.com/css/freestyle-script');

body {
    margin: 0;
    font-family: 'Cabinet Grotesk', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.banner {
    background-image: url("/src/Assets/Images/homepage_banner.jpg");
    background-size: cover;
    width: 100%;
    height: 80vh;
    align-items: center;
    justify-content: center;
    color: white;
}

@media (max-width: 1000px) {
    .banner {
        background-image: url("/src/Assets/Images/homepage_banner_mobile.jpg");
        background-position: center center;
    }
}

.partnership {
    position: relative;
    background-image: url("/src/Assets/Images/optik_4_darker.jpeg");
    background-size: cover;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: white;
    background-clip: content-box;
}

.partnership::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.slider-caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    padding: 2vw;
    border-radius: 10px;
}

.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.active {
    transform: translateY(0);
    transition: transform 0.3s;
}

.hidden {
    transform: translateY(-100%);
    transition: transform 0.3s;
}
